const TokenManager = {
    setAccessToken: (token) => {
        localStorage.setItem("alsharq_accessToken", token);
        const claims = TokenManager.decodeToken(token);
        localStorage.setItem("alsharq_claims", JSON.stringify(claims));
        return claims;
    },
    getAccessToken: () => {
        return localStorage.getItem("alsharq_accessToken");
    },
    getClaims: () => {
        const claims = localStorage.getItem("alsharq_claims");
        return claims ? JSON.parse(claims) : undefined;
    },
    clear: () => {
        localStorage.removeItem("alsharq_accessToken");
        localStorage.removeItem("alsharq_claims");
    },
    decodeToken: (token) => {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch (error) {
            console.error('Error decoding token:', error);
            return null;
        }
    }
};
export default TokenManager