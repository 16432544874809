import React, { useState, useEffect } from "react";

function PrayerTimes() {
  const [prayerTimes, setPrayerTimes] = useState({});
  const [loading, setLoading] = useState(true);
  const [locationError, setLocationError] = useState(false);
  const [city, setCity] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const fetchPrayerTimes = async (latitude, longitude) => {
      try {
        const response = await fetch(
          `https://api.aladhan.com/v1/timings?latitude=${latitude}&longitude=${longitude}&method=2`
        );
        const data = await response.json();
        setPrayerTimes(data.data.timings);
        setLoading(false);
      } catch (error) {
        console.error("Fout bij het ophalen van gebedstijden:", error);
      }
    };

    const fetchCityName = async (latitude, longitude) => {
      try {
        const response = await fetch(
          `https://api.openweathermap.org/geo/1.0/reverse?lat=${latitude}&lon=${longitude}&limit=1&appid=`
        );
        const data = await response.json();
        setCity(data[0]?.name || "Onbekende locatie");
      } catch (error) {
        console.error("Fout bij het ophalen van stadsnaam:", error);
      }
    };

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            fetchPrayerTimes(latitude, longitude);
            // fetchCityName(latitude, longitude);
          },
          (error) => {
            console.error("Fout bij het verkrijgen van locatie:", error);
            setLocationError(true);
            setLoading(false);
          }
        );
      } else {
        setLocationError(true);
        setLoading(false);
      }
    };

    const date = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    setCurrentDate(date.toLocaleDateString(undefined, options));
    getLocation();
  }, []);

  return (
    <div className="row">
      <div className="col-lg-5">
        <div className="timer-text">
          <h2>Gebedstijden</h2>
          {/* <p>Gebedstijden in {city}</p> */}
          <span>{currentDate}</span>
        </div>
      </div>
      <div className="col-lg-5 offset-lg-1">
        <div className="timer-num">
          {loading ? (
            <p>Gebedstijden laden...</p>
          ) : locationError ? (
            <p>
              Kan locatie niet ophalen. Schakel a.u.b. locatiediensten in uw browser in.
            </p>
          ) : (
            <ul>
              <li>Fajr: {prayerTimes.Fajr}</li>
              <li>Zonsopgang: {prayerTimes.Sunrise}</li>
              <li>Dhuhr: {prayerTimes.Dhuhr}</li>
              <li>Asr: {prayerTimes.Asr}</li>
              <li>Maghrib: {prayerTimes.Maghrib}</li>
              <li>Isha'a: {prayerTimes.Isha}</li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default PrayerTimes;
