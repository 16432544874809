import React from "react";
import Logo from "../../images/alsharqLogo.jpeg";
import HeaderTopbar2 from "../HeaderTopbar2";
import { Link, useNavigate } from "react-router-dom";
import MobileMenu from "../MobileMenu";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./style.css";
import TokenManager from "../../Apis/TokenManager";

const Header2 = () => {
  const push = useNavigate();
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = (section) =>{
    window.scrollTo(10, 0);
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
 }
  const handleLogOut = (e) => {
    e.preventDefault();
    TokenManager.clear();
    push("/");
  };
  return (
    <div className="middle-header">
      <HeaderTopbar2 />
      <div className="header-style-2">
        <div className="container">
          <div className="header-content">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                <div className="logo">
                  <Link onClick={ClickHandler} to="/" title="">
                    <img src={Logo} alt="" width={90}/>
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 d-lg-block d-none">
                <nav>
                  <ul>
                    <li>
                      <Link
                        onClick={ClickHandler}
                        className="active"
                        to="/"
                        title=""
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link onClick={()=>ClickHandler("about")} to="#" title="Over Ons">
                        Over Ons
                      </Link>
                    </li>
                    <li>
                      <Link onClick={()=>ClickHandler("services")} to="#" title="Activiteiten">
                        Activiteiten
                      </Link>
                    </li>
                    <li>
                      <Link onClick={()=>ClickHandler("events")} to="#" title="Evenementen">
                        Evenementen
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact" title="">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-1 col-md-6 col-sm-6 col-6">
                <div className="contact">
                  <div className="cart-search-contact">
                    {TokenManager.getAccessToken() && (
                      <div className="mini-cart">
                        <button className="cart-toggle-btn">
                          {" "}
                          <i className="fas fa-user"></i>{" "}
                        </button>
                        <div className="mini-cart-content">
                          <div className="mini-cart-items">
                            <div className="mini-cart-item clearfix">
                              <div className="mini-cart-item-des">
                                <Link onClick={ClickHandler} to="#">
                                  Mijn Abonnement
                                </Link>
                              </div>
                            </div>
                            <div className="mini-cart-item clearfix">
                              <div className="mini-cart-item-des">
                                <Link onClick={ClickHandler} to="#">
                                  Mijn Facturen
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="mini-cart-action clearfix">
                            <Link
                              onClick={handleLogOut}
                              to="/"
                              className="view-cart-btn theme-btn"
                            >
                              Uitloggen
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-2">
                <MobileMenu />
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header2;
