import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import ServiceSingle from '../../components/ServiceSingle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import { useParams } from 'react-router-dom';

const ServiceSinglePage = () => {
    const { id } = useParams();
    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'Dienst'} pagesub={'Dienst'} />
            <ServiceSingle id={id} />
            <Footer footerClass={'wpo-ne-footer-2'} />
            <Scrollbar />
        </Fragment>
    );
};

export default ServiceSinglePage;
