import React, { Component } from "react";
import Slider from "react-slick";
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './style.css'

class Hero2 extends Component {
    render() {
        var settings = {
            dots: true,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade: true
        };
        const ClickHandler=()=>{
            const element = document.getElementById('about');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        return (
            <section className="hero hero-style-1">
                <div className="hero-slider">
                    <Slider {...settings}>
                        <div className="slide s1">
                            <div className="container">
                                <div className="row">
                                    <div className="col col-lg-8 offset-lg-2 col-md-12 slide-caption">
                                        <div className="slide-top">
                                            <span>Laten We Elkaar Helpen</span>
                                        </div>
                                        <div className="slide-title">
                                            <h2>Levens Transformeren, Verbindingen Versterken</h2>
                                        </div>
                                        <div className="btns">
                                            <Link to="#" onClick={ClickHandler} className="theme-btn">Ontdek Meer</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        );
    }
}
export default Hero2;
