import axios from "axios"
import TokenManager from "./TokenManager"

const SWAGGER_BASE_URL = "https://app-alsharq-dev.azurewebsites.net/api/Auth"

const Auth={
    login: async (payload) => {
        try {
          const response = await axios.post(SWAGGER_BASE_URL + "/login", payload)
          const token = response.data.token
          TokenManager.setAccessToken(token)
          return { success: true, data: response.data.data } 
        } catch (error) {
          return { success: false, error: error.response.data };
        }
      },
      register:async (payload) =>{
        try{
            const response = await axios.post(SWAGGER_BASE_URL +"/register",payload,)
            return { success: true, data: response.data.message }
        } catch(error){
            return { success: false, error: error.response.data };
        }
      },
}
export default Auth