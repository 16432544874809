import React, { useState } from 'react'
import './style.css'
import { toast } from 'react-toastify'
import emailjs from 'emailjs-com'

const Newsletter = (props) => {
    const[email,setEmail]=useState("")

    const SubmitHandler = (e) => {
        if(email===""){
            toast.error("gelieve uw e-mail in te vullen")
        }
      e.preventDefault()

      const templateParams = {
        from_name: `Abonnement`,
        from_email: email,
        subject:"Deze klant wil zich abonneren",

    };

        emailjs.send('service_l9evnms', 'template_e0bc4o2', templateParams, 'jnAseA4fQoLZ1Yp0J')
            .then((response) => {
                toast.success("We nemen zo snel mogelijk contact met u op!")
                setEmail('')
            }, (err) => {
                console.log('MISLUKT...', err);
            });
    
    }
    return(
        <section className="wpo-news-letter-section">
            <div className="container">
                <div className="wpo-news-letter-wrap">
                    <div className="row">
                        <div className="col col-lg-10 offset-lg-1 col-md-8 offset-md-2">
                            <div className="wpo-newsletter">
                                <h3>Volg ons voor meer informatie</h3>
                                <p>Blijf verbonden met Alsharq om meer te weten te komen over onze nieuwste initiatieven, evenementen en manieren waarop u kunt deelnemen</p>
                                <div className="wpo-newsletter-form">
                                    <form onSubmit={SubmitHandler}>
                                        <div>
                                            <input type="text" placeholder="Vul uw e-mail in" 
                                            value={email}
                                            onChange={(e)=>setEmail(e.target.value)}
                                            className="form-control"/>
                                            <button type="submit">Abonneren</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Newsletter;
