import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import SidebarWrap from '../SidebarWrap'
import simg from '../../images/event-details2.jpg'

import './style.css'

const EventSingle = (props) => {

    const SubmitHandler = (e) =>{
        e.preventDefault()
    }

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

        return (
            <div className="wpo-event-details-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-8">
                            <div className="wpo-event-item">
                                <div className="wpo-event-img">
                                    <img src={simg} alt=""/>
                                    <div className="thumb-text">
                                        <span>25</span>
                                        <span>NOV</span>
                                    </div>
                                </div>
                                <div className="wpo-event-details-text">
                                    <h2>Leer over de Hadj</h2>
                                    <p>Aan de andere kant veroordelen we met rechtvaardige verontwaardiging mannen die zo verleid en gedemoraliseerd zijn door de charmes van het moment, dat ze de pijn en moeilijkheden die onvermijdelijk volgen niet kunnen voorzien. Gelijke schuld komt toe aan degenen die falen in hun plicht door zwakte van wil, wat hetzelfde is als zeggen dat ze werk en pijn schuwen.</p>
                                </div>
                                <div className="wpo-event-details-wrap">
                                    <div className="wpo-event-details-tab">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => { toggle('1'); }}
                                                >
                                                    Evenementschema
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => { toggle('2'); }}
                                                >
                                                Locatiekaart
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                        onClick={() => { toggle('3'); }}
                                                    >
                                                    Contacteer Ons
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <div className="wpo-event-details-content">
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1" id="Schedule">
                                                <p>Deze gevallen zijn perfect eenvoudig en gemakkelijk te onderscheiden. In een vrij uur, wanneer onze keuzemogelijkheid onbelemmerd is en niets ons weerhoudt te doen wat we het liefst doen, is elk genoegen welkom en moet elke pijn worden vermeden.</p>
                                                <ul>
                                                    <li>De wijze man handelt altijd verstandig in deze zaken.</li>
                                                    <li>In een vrij uur, wanneer onze keuzemogelijkheid onbelemmerd is.</li>
                                                    <li>Hij vermijdt pijn om erger leed te voorkomen.</li>
                                                    <li>We veroordelen met rechtvaardige verontwaardiging.</li>
                                                    <li>Hetzelfde geldt voor het vermijden van lasten.</li>
                                                    <li>De wijze man handelt altijd verstandig in deze zaken.</li>
                                                    <li>Keuzemogelijkheid en wanneer niets ons weerhoudt.</li>
                                                    <li>Hij vermijdt pijn om erger leed te voorkomen.</li>
                                                </ul>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="contact-map enent-map">
                                                    <iframe title='enent-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.9147703055!2d-74.11976314309273!3d40.69740344223377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1snl!2sbd!4v1547528325671"></iframe>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <div className="event-contact">
                                                    <div className="wpo-donations-details">
                                                        <form onSubmit={SubmitHandler} id="contact-form">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group">
                                                                    <input type="text" className="form-control" name="name" id="name" placeholder="Uw Naam*"/>
                                                                </div>
                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 form-group clearfix">
                                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Uw E-mail"/>
                                                                </div>
                                                                <div className="col-lg-12 col-12 form-group">
                                                                    <textarea className="form-control" name="note" id="note" placeholder="Bericht"></textarea>
                                                                </div>
                                                                <div className="submit-area col-lg-12 col-12">
                                                                    <button type="submit" className="theme-btn submit-btn">Nu Indienen</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SidebarWrap/>

                    </div>
                </div>
            </div>

            );
    }
    
    export default EventSingle;
