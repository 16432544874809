import React from "react";
import { Link } from "react-router-dom";
import tmr1 from "../../images/prayer-shape/2.png";
import tmr2 from "../../images/prayer-shape/1.png";
import tmr3 from "../../images/prayer-shape/3.png";
import VideoModal from "../ModalVideo";
import PrayerTimes from "./PrayerTimes";
import "./style.css";

const About = (props) => {
  const ClickHandler = (section) => {
    window.scrollTo(10, 0);
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="wpo-about-area section-padding" id="about">
      <div className="container">
        <div className="wpo-about-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="wpo-about-img-3">
                <img src={props.aboutImg} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 colsm-12">
              <div className="wpo-about-text">
                <div className="wpo-section-title">
                  <span>Over Ons</span>
                  <h2>Samen Voor Een Betere Toekomst</h2>
                </div>
                <p>
                Bij Alsharq zetten we ons in voor het bevorderen van positieve verandering 
                door gemeenschappen te versterken en culturele verschillen te overbruggen. 
                Onze missie is om een platform te creëren waar individuen en organisaties 
                samenkomen om te werken aan een inclusievere, rechtvaardigere en duurzamere 
                toekomst. Dit doen we door middel van educatie, belangenbehartiging en 
                samenwerkingsprojecten.
                </p>
                <div className="btns">
                  {/* <Link
                    onClick={() => ClickHandler("about")}
                    to="#"
                    title="About"
                    className="theme-btn"
                    tabIndex="0"
                    style={{display:'flex',alignItems:"center"}}
                  >
                    Discover More
                  </Link> */}
                  <ul>
                    <li className="video-holder">
                      {/* <Link to="#" className="" style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
                                        <VideoModal/>
                                        <p className='m-0'>Watch  Video</p>
                                                
                                        </Link> */}
                      <button
                        to="#"
                        title="video"
                        className="theme-btn-s2"
                        tabIndex="0"
                        style={{display:'flex',justifyContent:"space-between",alignItems:"center",paddingLeft:"4px",marginLeft:"30px",color:'#0d8342'}}
                      >
                        <VideoModal/>
                        <span style={{paddingLeft:'35px',}}>Bekijk Video</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="timer-section">
          <PrayerTimes />
          <div className="timer-shape1">
            <img src={tmr1} alt="" />
          </div>
          <div className="timer-shape2">
            <img src={tmr2} alt="" />
          </div>
          <div className="timer-shape3">
            <img src={tmr3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
