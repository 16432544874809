import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/alsharqLogo.jpeg";
import Newsletter from "../Newsletter";
import Bunduk from "../../images/bunduklogo.png";

import "./style.css";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className={`wpo-ne-footer ${props.footerClass}`}>
      <Newsletter />
      <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
          <div className="container">
            <div className="row">
              <div className="col col-lg-3 col-md-6 col-12">
                <div className="widget about-widget">
                  <div className="logo widget-title">
                    <img src={Logo} alt="" />
                  </div>
                  <p style={{ marginLeft: "25px" }}>
                    Alsharq islamitische organisatie{" "}
                  </p>
                  <ul style={{ marginLeft: "20px" }}>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/"></Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/">
                        <i className="ti-linkedin"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col col-lg-3 col-md-6 col-12">
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Diensten</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/service-single">
                        Islamitische School
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/service-single">
                        Onze Doelen
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/service">
                        Onze Diensten
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        Contacteer Ons
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/event">
                        Onze Evenementen
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col col-lg-2 col-md-6 col-12">
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Nuttige Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        Over Ons
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/service-single">
                        Activiteiten
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/event">
                        Semester
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/about">
                        Gebedstijden
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col col-lg-3 offset-lg-1 col-md-6 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="widget-title">
                    <h3>Contact</h3>
                  </div>
                  <p>Alsharq islamitische organisatie</p>
                  <div className="contact-ft">
                    <ul>
                      <li>
                        <i className="fi ti-location-pin"></i>Wijsthoek 302, 5403RG Uden
                      </li>
                      <li>
                        <i className="fi flaticon-call"></i>+31619319006
                      </li>
                      <li>
                        <i className="fi flaticon-envelope"></i>info@alsharq.com
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpo-lower-footer">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12">
                <p className="copyright">
                  &copy; 2024{" "}
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#ad5a45",
                    }}
                    href="https://www.bunduk.com/"
                  >
                    <img src={Bunduk} width={80} alt="" />
                  </a>{" "}
                  . Alle rechten voorbehouden
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
