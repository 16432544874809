import React from 'react';
import {Link} from 'react-router-dom'
import evnt from '../../images/blog/profile.png'
import ins1 from '../../images/recent-posts/img-4.jpg'
import ins2 from '../../images/recent-posts/img-6.jpg'
import ins3 from '../../images/recent-posts/img-5.jpg'

const SidebarWrap = () => {

    const SubmitHandler = (e) =>{
       e.preventDefault()
    }

    return(
        <div className="col col-lg-4 col-12">
            <div className="wpo-blog-sidebar">
                <div className="widget profile-widget">
                    <div className="profile-img">
                        <img src={evnt} alt="" />
                        <h2>Alexander Bartler</h2>
                        <p>Hallo! Dit is onze blog. Lees onze berichten en blijf bij ons.</p>
                    </div>
                    <div className="pro-social">
                        <ul>
                            <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                            <li><Link to="/"><i className="ti-instagram"></i></Link></li>
                            <li><Link to="/"><i className="ti-linkedin"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="widget search-widget">
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="Zoek bericht.."/>
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                <div className="widget recent-post-widget">
                    <h3>Aankomend Evenement</h3>
                    <div className="posts">
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins1} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/event/1">Veel kinderen lijden zwaar door voedselgebrek.</Link></h4>
                                <span className="date">22 sep 2024</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins2} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/event/2">Wees vriendelijk voor arme mensen en kinderen.</Link></h4>
                                <span className="date">22 sep 2024</span>
                            </div>
                        </div>
                        <div className="post">
                            <div className="img-holder">
                                <img src={ins3} alt=""/>
                            </div>
                            <div className="details">
                                <h4><Link to="/event/3">Wees zacht en vriendelijk voor de armen.</Link></h4>
                                <span className="date">22 sep 2024</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link to="/">Salat</Link></li>
                        <li><Link to="/">Koran</Link></li>
                        <li><Link to="/">Boeken</Link></li>
                        <li><Link to="/">Onderwijs</Link></li>
                        <li><Link to="/">Wereldwijd</Link></li>
                        <li><Link to="/">Huwelijk</Link></li>
                        <li><Link to="/">Hadj</Link></li>
                        <li><Link to="/">Gezondheid</Link></li>
                    </ul>
                </div>
            </div>
        </div>
     )
}

export default SidebarWrap;
