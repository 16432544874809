import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';

class ContactForm extends Component {

    state = {
        name: '',
        email: '',
        subject: '',
        lastname: '',
        notes: '',
        error: {}
    }

    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';

        this.setState({
            [e.target.name]: e.target.value,
            error
        });
    }

    submitHandler = (e) => {
        e.preventDefault();

        const { name, email, subject, lastname, notes, error } = this.state;

        // Eenvoudige validatiecontroles
        if (name === '') error.name = "Voer uw naam in, alstublieft";
        if (email === '') error.email = "Voer uw e-mail in, alstublieft";
        if (subject === '') error.subject = "Voer uw onderwerp in, alstublieft";
        if (lastname === '') error.lastname = "Voer uw achternaam in, alstublieft";
        if (notes === '') error.notes = "Voer uw notitie in, alstublieft";

        this.setState({ error });

        if (!Object.values(error).some((err) => err)) {
            const templateParams = {
                from_name: `${name} ${lastname}`,
                from_email: email,
                subject,
                notes
            };

            emailjs.send('service_l9evnms', 'template_e0bc4o2', templateParams, 'jnAseA4fQoLZ1Yp0J')
                .then((response) => {
                    toast.success("We nemen zo snel mogelijk contact met u op!")
                    console.log('SUCCES!', response.status, response.text);
                    this.setState({
                        name: '',
                        email: '',
                        subject: '',
                        lastname: '',
                        events: '',
                        notes: '',
                        error: {}
                    });
                }, (err) => {
                    console.log('MISLUKT...', err);
                });
        }
    }

    render() {
        const { name, email, subject, lastname, error } = this.state;

        return (
            <form onSubmit={this.submitHandler} className="form">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Naam" />
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-field">
                            <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Achternaam" />
                            <p>{error.lastname ? error.lastname : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="E-mail" />
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder="Onderwerp" />
                            <p>{error.subject ? error.subject : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea name="notes" placeholder="Bericht" onChange={this.changeHandler}></textarea>
                            <p>{error.notes ? error.notes : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="theme-btn btn-donate">Bericht Verzenden</button>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default ContactForm;
