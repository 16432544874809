import React from "react";
import Logo from "../../images/alsharqLogo.jpeg";
import { Link, useNavigate } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar";
import MobileMenu from "../../components/MobileMenu";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./style.css";
import TokenManager from "../../Apis/TokenManager";

const Header = () => {
  const push = useNavigate();
  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleLogOut = (e) => {
    e.preventDefault();
    TokenManager.clear();
    push("/");
  };

  return (
    <div className="middle-header">
      <HeaderTopbar />
      <div className="header-style-3">
        <div className="container">
          <div className="header-content">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                <div className="logo">
                  <Link onClick={ClickHandler} to="/" title="">
                    <img src={Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-8 d-none  d-sm-none  d-md-none d-lg-flex align-items-center justify-content-center">
                <nav>
                  <ul>
                    <li>
                      <Link
                        onClick={ClickHandler}
                        to="/"
                        title=""
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/" title="">
                        Over Ons
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/" title="">
                        Diensten
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/" title="">
                        Evenement
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to="/contact" title=""                         className="active"> 
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-lg-1 col-md-6 col-sm-6 col-6">
                <div className="contact">
                  <div className="cart-search-contact">
                    {TokenManager.getAccessToken() && (
                      <div className="mini-cart">
                        <button className="cart-toggle-btn">
                          <i className="fas fa-user"></i>
                        </button>
                        <div className="mini-cart-content" style={{textAlign:"center"}} >
                          <div className="mini-cart-items" >
                            <div className="mini-cart-item clearfix">
                              <div className="mini-cart-item-des">
                                <Link onClick={ClickHandler} to="#">
                                  Mijn Abonnement
                                </Link>
                              </div>
                            </div>
                            <div className="mini-cart-item clearfix">
                              <div className="mini-cart-item-des">
                                <Link onClick={ClickHandler} to="#">
                                  Mijn Facturen
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="mini-cart-action clearfix">
                            <Link
                              onClick={handleLogOut}
                              to="/"
                              className="view-cart-btn theme-btn"
                              style={{margin:"0 auto"}}
                            >
                              Uitloggen
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-sm-2 col-2">
                <MobileMenu />
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
