import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './style.scss';
import Auth from '../../Apis/Auth';

const SignUpPage = (props) => {

    const push = useNavigate();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState({
        email: '',
        volledige_naam: '',
        wachtwoord: '',
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});

        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (value.email !== '' || value.volledige_naam !== '' || value.wachtwoord !== '') {
            const response = await Auth.register({
                name: value.volledige_naam,
                email: value.email,
                password: value.wachtwoord
            });
            if (response.success) {
                validator.hideMessages();
                toast.success('Registratie succesvol afgerond!');
                push('/login');
            } else {
                validator.showMessages();
                response.error.map((err) => toast.error(err.description));
            }
            setLoading(false);
        } else {
            validator.showMessages();
            toast.error('Lege velden zijn niet toegestaan!');
        }
        setLoading(false);
    };

    return (
        <Grid className="loginWrapper">

            <Grid className="loginForm">
                <h2>Registreren</h2>
                <p>Maak een account aan</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Volledige naam"
                                value={value.volledige_naam}
                                variant="outlined"
                                name="volledige_naam"
                                label="Naam"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                type='password'
                                fullWidth
                                placeholder="Wachtwoord"
                                value={value.wachtwoord}
                                variant="outlined"
                                name="wachtwoord"
                                label="Wachtwoord"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={loading} fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">
                                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Aanmelden"}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="instagram"><i className="fa fa-instagram"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid>
                            <p className="noteHelp">Heb je al een account? <Link to="/login">Ga terug naar Inloggen</Link></p>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    );
};

export default SignUpPage;
