import React from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../images/service-details.jpg'
import service2 from '../../images/service-detail2.jpg'
import './style.css'

const ServiceSingle = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
      
    return(
        <section className="service-single-section section-padding"> 
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-md-12 col-12">
                        <div className="service-single-content">
                            <div className="service-single-img">
                                <img src={service1} alt=""/>
                            </div>
                            <h2>Het Varaus Hotel & Resort</h2>
                            <p>Er zijn veel variaties van passages van Lorem Ipsum beschikbaar, maar de meeste zijn in enige vorm gewijzigd, door geïnjecteerde humor, of willekeurige woorden die er niet eens een beetje geloofwaardig uitzien. Als je een passage van Lorem Ipsum gaat gebruiken, moet je ervoor zorgen dat er niets genants verborgen zit in het midden van de tekst. Alle Lorem Ipsum-generatoren op het internet herhalen naar behoefte vooraf gedefinieerde stukjes tekst, waardoor dit de eerste echte generator op internet is.</p>
                            <p>Het gebruikt een woordenboek van meer dan 200 Latijnse woorden, gecombineerd met een handvol voorbeeldzinsstructuren, om Lorem Ipsum te genereren dat redelijk lijkt. De gegenereerde Lorem Ipsum is daarom altijd vrij van herhalingen en geïnjecteerde humor, en er is niets genants verborgen in de tekst. Alle Lorem Ipsum-generatoren op het internet herhalen vooraf gedefinieerde stukjes tekst.</p>
                            <div className="service-single-tb">
                                <div className="service-single-inner">
                                    <span><img src={service2} alt=""/></span>
                                    <div className="service-single-tb-text">
                                        <p>Alle Lorem Ipsum-generatoren op het internet herhalen naar behoefte vooraf gedefinieerde stukjes tekst, waardoor dit de eerste echte generator op internet is.</p>
                                        <p>Het gebruikt een woordenboek van meer dan 200 Latijnse woorden, gecombineerd met een handvol voorbeeldzinsstructuren,</p>
                                    </div>
                                </div>
                            </div>
                            <div className="service-single-tb-content">
                                <p>Er zijn veel variaties van passages van Lorem Ipsum beschikbaar, maar de meeste zijn in enige vorm gewijzigd, door geïnjecteerde humor of willekeurige woorden.</p>
                            </div>
                        </div>
                        <div className="prv-nx">
                            <div className="pre-btn"><Link onClick={ClickHandler} to="/service/2">Vorige</Link></div>
                            <div className="nex-btn"><Link onClick={ClickHandler} to="/service/3">Volgende</Link></div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-12 col-12">
                        <div className="service-sidebar">
                            <div className="widget service-list-widget">
                                <h3>Alle Diensten</h3>
                                <ul>
                                    <li className="current"><Link to="/service/1">Speciale Kinderzorg</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service/2">Moskeeontwikkeling</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service/3">Huwelijk</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service/4">Begrafenissen</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service/5">Help Armen</Link></li>
                                </ul>
                            </div>
                            <div className="widget contact-widget">
                                <div>
                                    <h4>Vraag een Terugbelfunctie aan</h4>
                                    <h2>0658760191</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceSingle;
